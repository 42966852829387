import { Pagination } from "@mui/material";
import { PaginationStore } from "@root/stores/paginationStore";
import { Event } from "@root/utils/entities";
import { observer } from "mobx-react-lite";

interface EventPaginationProps {
    events: Event[];
    store: PaginationStore;
    onChange: (newPage: number) => void;
}

export const EventPagination = observer(({ events, store, onChange }: EventPaginationProps) => {
  if (!events || events!.length === 0) return undefined;
  return (
    <Pagination
      count={store.pageCount}
      page={store.page + 1}
      boundaryCount={0}
      siblingCount={0}
      onChange={async (_, page) => {
        store.setPage(page - 1);
        onChange(page);        
      }}
      color="primary"
      sx={{ display: "flex", justifyContent: "center" }}
    />
  );
});
