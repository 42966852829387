import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Event } from "@root/utils/entities";
import { observer } from "mobx-react-lite";
import { EventPagination } from "./EventPagination";
import { PaginationStore } from "@root/stores/paginationStore";
import { EventListItem } from "./EventListItem";

interface EventListProps {
  events: Event[];
  paginationStore: PaginationStore;
  onPageChange: (newPage: number) => void;
  onViewEvent: (event: Event) => void;
}

export const EventList = observer(
  ({ events, paginationStore, onPageChange, onViewEvent }: EventListProps) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container sx={{ py: 0 }} spacing={2}>
          {events?.map((event, index) => (
            <Grid
              item
              key={`event${index}_${event.id.substring(0, 4)}`}
              xs={smallScreen ? 12 : mediumScreen ? 6 : 12}
            >
              <EventListItem event={event} onView={() => onViewEvent(event)} />
            </Grid>
          ))}
        </Grid>
        <EventPagination
          events={events}
          store={paginationStore}
          onChange={onPageChange}
        />
      </Grid>
    );
  }
);
